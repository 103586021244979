// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contribute-js": () => import("./../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-ingredient-js": () => import("./../src/templates/Ingredient.js" /* webpackChunkName: "component---src-templates-ingredient-js" */),
  "component---src-templates-ingredients-js": () => import("./../src/templates/Ingredients.js" /* webpackChunkName: "component---src-templates-ingredients-js" */),
  "component---src-templates-location-js": () => import("./../src/templates/Location.js" /* webpackChunkName: "component---src-templates-location-js" */),
  "component---src-templates-locations-js": () => import("./../src/templates/Locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-recipe-js": () => import("./../src/templates/Recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-recipes-js": () => import("./../src/templates/Recipes.js" /* webpackChunkName: "component---src-templates-recipes-js" */)
}

